















































import './scss/BaseTextarea.scss'
import './scss/BaseTextareaAdaptive.scss'
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'BaseTextarea',
})
export default class BaseTextarea extends Vue {
    $refs!: {
        input: HTMLFormElement,
    }

    @Prop({ default: '' }) value!: string;
    @Prop({ default: 'text' }) type!: string;
    @Prop({ default: '' }) label!: string;
    @Prop({ default: '' }) placeholder!: string;
    @Prop({ default: '' }) required!: string;
    @Prop({ default: '' }) caption!: string;
    @Prop({ default: '' }) inputClass!: string;
    @Prop({ default: false }) error!: boolean;

    emitInput(value: string) {
        this.$emit('input', value);
    }

    focusInput() {
        this.$refs.input.focus();
    }
}
